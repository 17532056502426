.crudBlockContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.crudButtons {
    background-color: #0abab5;
    color: #ffffff;
    border: none; /* Rimuove il bordo */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Aggiunge un'ombra sotto */
    display: flex;
    align-items: center;
    justify-content: center;
    transition:  background-color 0.3s; /* Aggiunge una transizione fluida per il colore di sfondo */
}

.crudButtons:hover {
    cursor: pointer;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6); /* Aggiunge un'ombra sotto */
    background-color: rgba(4, 119, 115, 0.9); /* Rende il colore leggermente più scuro */
}


.crudButtonsSave {
    background-color: #1a59a1; /* Colore tiffany */
    color: #ffffff;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition:  background-color 0.3s;
}

.crudButtonsSave:hover {
    cursor: pointer;
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    background-color: #0f3e74; /* Colore tiffany */
}

.crudButtonsErase {
    background-color: rgba(247, 50, 15, 0.9); /* Rende il colore leggermente più scuro (rosso) */
    color: #ffffff;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.crudButtonsErase:hover {
    cursor: pointer;
    color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    background-color: rgba(170, 40, 17, 0.9); /* Rende il colore leggermente più scuro (rosso) */
}

