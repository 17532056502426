.dashboardNavbar {
  background-color: #0abab5;

}

.loggedDropdown {
  z-index: 1000;
  border-radius: 50px;
  background-color: #0abab5;
  padding: 9px;
  transition: background-color 0.4s; /* Aggiunge una transizione fluida per il colore di sfondo */
}

.loggedDropdown:hover {
  background-color: rgba(8, 131, 127, 0.9); /* Cambia il colore durante l'hover */
}

.dropdownItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px; // Spazio tra gli elementi della lista
}

.dropdown-header {
  margin-bottom: 8px; // Spazio tra i loghi e la dicitura
}

.dropdown-title {
  gap: 8px; // Spazio tra i loghi e la dicitura
}





