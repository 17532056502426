/* Footer.module.scss */

.footer {
  background-color: #001f3f;
  padding: 30px 0 30px;
  margin-top: auto;
  color: rgb(207, 207, 207);

  h6 {
    color: white;
    font-weight: bold;
  }

  p{
    color: rgb(207, 207, 207);
    margin: 0;
    padding: 0;
  }
  .icon {
    color: rgb(207, 207, 207);
  }

  p:not(.payoff):not(.allRightsReserved):hover {
    cursor: pointer;
    text-decoration: underline;
    color: #ffd000;
  }
  
  
  .icon:hover {
    cursor: pointer;
    color: #ffd000;
  }
}
