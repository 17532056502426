.homeImageRow {
  height: 1000px;
}

.mainDiv {
  position: fixed;
  top: 0;
  left: 0;
}

.homeImageCol {
  position: relative;
}

.homeImage {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.titleContainerDiv {
  z-index: 2;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  opacity: 0;
}

.homepageTitle {
  font-size: 70px;
}

// Classes

.slideLeftElement {
  position: absolute;
  top: 0;
  left: 400px;
  object-fit: cover;
  height: 100%;
  border-radius: 50px;
  opacity: 0;
}

.slide-left {
  -webkit-animation: slide-left 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  animation: slide-left 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

// Keyframes
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
    opacity: 1;
  }
}

.slideRightElement {
  position: absolute;
  top: 0;
  right: 400px;
  object-fit: cover;
  height: 100%;
  border-radius: 50px;
  opacity: 0;
}

.slide-right {
  -webkit-animation: slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
    opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(400px);
    transform: translateX(400px);
    opacity: 1;
  }
}

.image-show {
  animation: image-show 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

// Keyframes

@keyframes image-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.titleContainerDiv-show {
  animation: titleContainerDiv-show 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s
    forwards;
}

// Keyframes

@keyframes titleContainerDiv-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.getStartedButton {
  font-weight: bold;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.getStartedButton:hover {
  transform: scale(1.1);
}

 .payoff {
   opacity: 0;
 }

.scale-in-bottom {
  -webkit-animation: scale-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    forwards;
  animation: scale-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
