.myNavStyle {
    color: white
}

.myNavNotScrolled {
    display: flex;
    background-color: #001f3f00;
    transition: background-color 0.5s ease; /* Transizione fluida per il cambio di colore di sfondo */

}

.myNavScrolled{
    background-color: #001f3f; /* Colore di sfondo predefinito */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.76);
    transition: background-color 0.5s ease; /* Transizione fluida per il cambio di colore di sfondo */

}

.navText {
    color: white; /* Colore del testo */
    font-weight: bold; /* Grassetto */
  }


.myNavDivButtons {
    display: flex;
}

.myNavDivButtonsCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navLink {
    margin-right: 20px;
    color: white; // Colore bianco per tutti i link nella navbar
    text-decoration: none; // Rimuovi la sottolineatura per il link attivo

  }
  
.navLink:hover {
    color: #ffd000; // Colore grigio per il link durante l'hover
  }
  .navLink:focus {
    text-decoration: underline; // Rimuovi la sottolineatura per il link attivo
  }