.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #f00; /* Colore a tua scelta */
  }
  
  .not-found-text {
    font-size: 1.5rem;
    color: #333; /* Colore a tua scelta */
  }
  