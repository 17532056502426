.dashboardNavbar {
  position: sticky;
  top: 70px;
  left: 0px;
}

.buttonDashboardNavbar {
  height: 150px;
  border: none; /* Rimuove il bordo */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6); /* Aggiunge un'ombra sotto */
  background-color: #0abab5;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
  border-radius: 0px 10px 10px 0px;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
}

.buttonDashboardNavbar:hover {
  background-color: rgba(
    4,
    119,
    115,
    0.9
  );
  cursor: pointer;
  transform: scale(
    1.1
  ); /* Aumenta leggermente le dimensioni al passaggio del mouse */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9); /* Aggiunge un'ombra al passaggio del mouse */
}

.tileDashboard {
  color: rgba(4, 95, 92, 0.9);
  text-decoration: none;
font-weight: bold;
}

.tileDashboard:hover {
  cursor: pointer;
  color: rgba(4, 95, 92, 0.9);
}

.dashboardOffCanvasElement {
  color: rgba(4, 95, 92, 0.9);
}

.dashboardOffCanvasElement {
  cursor: pointer;
  color: rgba(4, 95, 92, 0.9);

}